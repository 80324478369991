<template>
    <div class="replace">
    </div>
</template>
<script>
import router from '../router'
export default{
    name: 'Replace',
    mounted(){
        router.replace("/")
    }
}
</script>
